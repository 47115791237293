#root{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.sidebar .MuiFormControl-root{
	width:100%;
}
.sidebar{
	width: 350px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
}
.sidebar .filterbox{
	padding: 25px;
	width: 100%;
	overflow-y: auto;
	position: absolute;
	top: 65px;
	bottom: 15px;
	left: 0;
	right: 0;
	box-sizing: border-box;
}
.adminbar{
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	padding: .5rem 1rem;
	background: #F0F0F8;
}
.filter{
	margin:0.7rem 0;
}
.brand{
	background-color: #888e94;
	padding: 7px 20px;
}
.sidebar .brand{
	display: flex;
	align-items: center;
}
.state-filter, .state-filter .MuiInputBase-root{
	margin-left: 15px;
}
#map{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 350px;
}
#Legend {
    position: absolute;
    bottom: 2em;
    right: 2em;
    background: #ffffff;
    z-index: 1000;
    padding: 1rem;
}
#Legend ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

#Legend span {
    display: inline-block;
    width: 1em;
    height: 1em;
}
#login{
	max-width:400px;
	border-radius: 10px;
	overflow: hidden;
	background: #fff;
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -60%);
	z-index: 100;
}
#login .brand{
	text-align: center;
}
#login h2{
	text-align: center;
}
#login .wrapper{
	padding:0 2rem 2rem;
}
#login .MuiFormControl-root{
	margin-bottom:0.5rem;
}
.resetlink{
	font-size:0.7rem;
	margin-bottom:1rem;
	text-align: center;
	display: block;
	color: #3f51b5;
}